import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main>
        <h1 style={{fontSize: '5.5em'}}>404</h1>
        <h2>К сожалению, страница не существует</h2>
        <Link to={`/`}>Вернуться на главную</Link>
    </main>
  </Layout>
)

export default NotFoundPage
